<template>
  <div class="template">
    <v-app-bar app color="black" dark fixed>
      <v-toolbar-title class="grey--text">Edit</v-toolbar-title>

      <template v-if="isSaving">
        <v-progress-linear indeterminate></v-progress-linear>
      </template>
      <v-spacer v-else></v-spacer>

      <template v-if="user!== null">
        <v-btn color="white" text to="/my/settings">
          Cancel
        </v-btn>
      </template>

      <!-- <v-btn color="white" class="black--text" @click="updateUser" >
      Save <v-icon color="primary" class="ml-1">mdi-content-save-outline</v-icon>
    </v-btn> -->

    <v-btn dark class="bg-loop" @click="updateUser" >
      Save <v-icon color="white" class="ml-1">mdi-content-save-outline</v-icon>
    </v-btn>

  </v-app-bar>

    <div class="profile page bg-loop faded" :class="{'mobile': $vuetify.breakpoint.smAndDown}">
      <v-container fill-height fluid class="align-start pt-10">



<ValidationObserver tag="form"  ref="observer" >
  <!-- <section style="padding-top:9vh" class="grey lighten-3" v-if="!$isEmpty(localUser)">
      <v-row align="center" justify="center">
        <v-col cols="auto">
          <v-row class="text-center">
            <v-col>
              <v-avatar size="40" :color="!$v.localUser.firstName.$invalid && !$v.localUser.lastName.$invalid ? 'green' : 'grey'">
                <v-icon :color="!$v.localUser.firstName.$invalid && !$v.localUser.lastName.$invalid ? 'white' : 'grey darken-2'">mdi-account</v-icon>
              </v-avatar>
              <small class="text--secondary d-block">name</small>
            </v-col>
            <v-col>
              <v-avatar size="40" :color="localUser.description.length > 10 ? 'green' : 'grey'">
                <v-icon :color="localUser.description.length > 10 ? 'white' : 'grey darken-2'">mdi-chat</v-icon>
              </v-avatar>
              <small class="text--secondary d-block">bio</small>
            </v-col>
            <v-col>
              <v-avatar size="40" :color="localUser.links.length > 0 && !$isEmpty(localUser.links[0]) ? 'green' : 'grey'">
                <v-icon :color="localUser.links.length > 0 && !$isEmpty(localUser.links[0]) ? 'white' : 'grey darken-2'">mdi-check-decagram</v-icon>
              </v-avatar>
              <small class="text--secondary d-block">social</small>
            </v-col>
            <v-col>
              <v-avatar size="40" :color="!$v.localUser.rate.$invalid ? 'green' : 'grey'">
                <v-icon :color="!$v.localUser.rate.$invalid ? 'white' : 'grey darken-2'">mdi-currency-usd</v-icon>
              </v-avatar>
              <small class="text--secondary d-block">rate</small>
            </v-col>
            <v-col>
              <v-avatar size="40" :color="skills.length > 2 ? 'green' : 'grey'">
                <v-icon :color="skills.length > 2 ? 'white' : 'grey darken-2'">mdi-brain</v-icon>
              </v-avatar>
              <small class="text--secondary d-block">skills</small>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="auto" class="text-center text-md-left">
          <strong>Profile Confidence </strong>
          <p class="text--secondary mb-0">The more information, the more credible you appear.</p>
        </v-col>
      </v-row>
  </section> -->

    <v-row align="start" justify="center" v-if="!$isEmpty(localUser)" class="fill-height" no-gutters>
      <v-col cols="12" md="10" lg="8">
        <form name="localUser"></form>
        <v-row class="white elevation-10 rounded-lg" no-gutters>
          <v-col cols="12" md="4" class="text-center profile-details grey lighten-1 white--text pa-md-2">

            <div class="profile-photo pt-8 pt-md-0" v-if="$vuetify.breakpoint.mdAndUp">
              <template v-if="!$isEmpty(localUser.photoURL)">
                <img :src="`${localUser.photoURL.cropped}?version=1`" alt="" class="profile-img">
              </template>
              <template v-else>
                <img :src="localUser.basicAvatar" alt="" class="profile-img">
              </template>
              <v-btn
                color="white"
                class="edit-photo"
                @click="dialog = true"
                small
                rounded
              >
                edit image
              </v-btn>
              <upload-image v-if="dialog" v-on:uploaded-photos="uploadedPhotos" @closeModal="closeModal"></upload-image>
            </div>
            <div v-if="$vuetify.breakpoint.mdAndUp">
              <!-- <h2>{{localUser.firstName}} {{localUser.lastName}}</h2> -->
              <!-- <div class="company-links">
                <div><a href="#" class="white--text">@Keybot</a></div>
                <div><a href="#" class="white--text">@HighGravity</a></div>
              </div> -->

            </div>
          </v-col>


          <v-col cols="12" md="8" class="pa-md-4 pa-0 pt-4 pt-md-0">

            <v-tabs v-model="tab"  color="primary" grow :show-arrows="false">
              <!-- <v-tabs-slider color="yellow"></v-tabs-slider> -->
              <v-tab key="about">About Me</v-tab>
              <v-tab key="skills">Skills</v-tab>
              <v-tab key="social">Social</v-tab>
              <!-- <v-tab key="pro"><v-icon size="16" class="mr-2">mdi-check-decagram</v-icon> Pro</v-tab> -->
            </v-tabs>

            <v-tabs-items v-model="tab" class="px-4">

                  <v-tab-item key="about" class="pt-6 pb-6">
                    <!-- if mobile -->
                    <div class="profile-photo pt-8 pt-md-0" v-if="$vuetify.breakpoint.smAndDown">
                      <template v-if="!$isEmpty(localUser.photoURL)">
                        <img :src="`${localUser.photoURL.cropped}?version=1`" alt="" class="profile-img" @click="dialog = true">
                      </template>
                      <template v-else>
                        <img :src="localUser.basicAvatar" alt="" class="profile-img" @click="dialog = true">
                      </template>
                      <v-btn
                        color="white"
                        class="edit-photo"
                        @click="dialog = true"
                        small
                        rounded
                      >
                        edit image
                      </v-btn>
                      <upload-image v-if="dialog" v-on:uploaded-photos="uploadedPhotos" @closeModal="closeModal"></upload-image>
                    </div>
                    <!-- template -->
                    <div class="mb-8">
                        <h5 class="app-heading mb-1">Who are you?</h5>
                        <v-row class="mb-2">
                          <v-col cols="6" class="pl-0">
                            <ValidationProvider rules="required|min:2" v-slot="{ errors }">
                              <v-text-field
                              placeholder="First Name"
                              solo flat background-color="grey lighten-3 pr-" hide-details="auto"
                              v-model.trim="localUser.firstName"
                              :error-messages="errors[0]"
                              dense
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="6" class="pl-0 pr-0">
                            <ValidationProvider rules="required|min:2" v-slot="{ errors }">
                              <v-text-field
                              placeholder="Last Name"
                              solo flat background-color="grey lighten-3" hide-details="auto"
                              v-model="localUser.lastName"
                              :error-messages="errors[0]"
                                dense

                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </div>

                    <div class="mb-8">
                        <h5 class="app-heading mb-1">What user name would you like?</h5>
                        <v-row class="mb-2" align="start">
                          <!-- <v-col cols="auto">
                            <svg class="at-sign" xmlns:x="http://ns.adobe.com/Extensibility/1.0/" xmlns:i="http://ns.adobe.com/AdobeIllustrator/10.0/" xmlns:graph="http://ns.adobe.com/Graphs/1.0/" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" enable-background="new 0 0 100 100" xml:space="preserve"><switch><foreignObject requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/" x="0" y="0" width="1" height="1"/><g i:extraneous="self"><path d="M70.343,91.344C62.544,94.836,55.784,96,46.555,96C24.978,96,6,80.611,6,55.267C6,28.888,25.368,5.999,54.614,5.999    C77.622,5.999,94,21.646,94,43.37c0,19.009-10.659,30.905-24.697,30.905c-6.109,0-10.528-3.233-11.179-9.957h-0.26    c-4.16,6.466-9.879,9.957-16.898,9.957c-8.319,0-14.558-6.336-14.558-16.939c0-15.905,11.829-30.13,30.546-30.13    c5.719,0,12.218,1.423,15.338,3.104l-3.899,24.052c-1.17,7.63-0.26,11.121,3.38,11.25c5.589,0.259,12.608-6.854,12.608-21.854    c0-16.938-10.919-29.87-31.066-29.87c-20.018,0-37.306,15.388-37.306,40.215c0,21.725,13.778,33.88,33.146,33.88    c6.629,0,13.779-1.422,18.978-4.009L70.343,91.344z M58.904,37.939c-1.04-0.259-2.47-0.518-4.03-0.518    c-8.579,0-15.339,8.405-15.339,18.361c0,4.915,2.21,8.018,6.37,8.018c4.939,0,10.009-6.077,11.049-13.577L58.904,37.939z"/></g></switch></svg>

                          </v-col> -->
                          <v-col cols="8" class="pl-0">
                            <ValidationProvider rules="required|unique" :debounce="500" v-slot="{ errors }">
                              <v-text-field
                              placeholder="personName"
                              prefix="@"
                              solo flat background-color="grey lighten-3" hide-details="auto"
                              v-model.trim="localUser.userName"
                              :error-messages="errors[0]"
                              :append-outer-icon="$isEmpty(errors[0]) ? 'mdi-checkbox-marked-circle':'mdi-cancel'"
                              dense
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </div>


                      <div class="mb-8">
                        <h5 class="app-heading">A little about you 🤷<small class="text--disabled">*optional</small></h5>
                        <v-textarea
                        solo flat background-color="grey lighten-3"
                        auto-grow
                        label="Description"
                        rows="1"
                        row-height="20"
                        v-model.trim="localUser.description"
                        :value="localUser.description"
                        ></v-textarea>
                      </div>

                  </v-tab-item>

                  <v-tab-item key="skills" class="pt-3 pb-6">
                    <div class="mb-8">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <v-row  class="mb-2" no-gutters>
                          <v-col cols="12" class="pb-0">
                            <h5 class="app-heading mb-0">What's your time worth?</h5>
                            <p class="text--secondary text-body-2 mb-2">Our community grows when everyone's time is worth something.</p>
                            <div class="bg-loop white--text d-flex align-center rounded justify-center py-2">
                              <v-icon color="white">mdi-currency-usd</v-icon>

                                <imask-input
                                class="rate"
                                  v-model="localUser.rate"
                                  :mask="currency"
                                  :unmask="'typed'"
                                >

                                </imask-input>

                              <strong class="pl-2">/ hr</strong>
                            </div>
                          </v-col>
                          <v-col cols="12" v-if="!$isEmpty(errors)" class="text-center">
                              <div class="error lighten-4 pa-3">
                                <span class="error--text">{{ errors[0] }}</span>
                              </div>
                          </v-col>
                        </v-row>
                      </ValidationProvider>
                    </div>


                                  <div class="">
                                    <!-- <v-divider class=" my-5"></v-divider> -->
                                    <h5 class="app-heading mb-0">What can people meet you about?</h5>
                                    <p class="text--secondary text-body-2 mb-2">Type to <i>search</i> for skills or <i>add</i> your own</p>
                                    <v-combobox
                                      class="skills"
                                      color="grey lighten-2"
                                      v-model="skills"
                                      :items="skillsList"
                                      item-text="name"
                                      item-value="name"
                                      :search-input.sync="search"
                                      prepend-inner-icon="mdi-magnify"
                                      chips
                                      multiple
                                      placeholder="Baking, Presentations, Cycling, ect..."
                                      label="Skills/Expertise"
                                      solo flat background-color="grey lighten-3"
                                      return-object
                                    >
                                      <!-- <template v-slot:selection="{ attrs, item, select, selected }">
                                        <v-chip
                                          v-bind="attrs"
                                          :input-value="selected"
                                          close
                                          color="black"
                                          dark
                                          @click:close="removeItem(item)"
                                        >
                                          <strong class="">{{ item.name }}</strong>&nbsp;
                                        </v-chip>
                                      </template> -->
                                      <template v-slot:item="{item, index}">
                                          <span class="" ><v-icon size="14" color="green" class="mr-2" v-if="inSkillsList(item.name)">mdi-check</v-icon> {{ item.name }}</span>&nbsp;
                                      </template>

                                      <template v-slot:no-data>
                                        <v-list-item>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              <div class="primary--text mb-2">No results matching "<strong>{{ search }}</strong>".</div>
                                              <div>Press <kbd class="py-1 d-inline">&rdsh; enter</kbd> to create a new one</div>
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </template>
                                    </v-combobox>
                                    <!-- :loading="isLoading" -->
                                    <!-- <v-autocomplete
                                      v-model="skills"
                                      :items="skillsList"
                                      :search-input.sync="searchSkills"
                                      color="primary"
                                      solo
                                      hide-no-data
                                      hide-selected
                                      item-text="name"
                                      item-value="API"
                                      label="Search skills and interests..."
                                      placeholder="Start typing to Search"
                                      prepend-icon="mdi-database-search"
                                      return-object
                                    ></v-autocomplete> -->
                      <div class="skills mx-2">
                        <v-row no-gutters class="text--disabled text-caption">
                          <v-col cols="4">skill / interest</v-col>
                          <v-spacer></v-spacer>
                          <v-col cols="2" class="text-center">meetings</v-col>
                          <v-col cols="2"  class="text-center">rating</v-col>
                          <v-col cols="auto" class="ml-3 ml-md-6">remove</v-col>
                        </v-row>
                        <v-row class="skill pa-1" no-gutters v-for="(skill, index) in sortedSkills" :key="`skill-${index}`" align="center" justify="start">
                          <v-col cols="4">
                              <v-chip v-if="skill.new" class="" color="primary">{{skill.name}}</v-chip>
                              <v-chip v-else class="" dark color="black">{{skill.name}}</v-chip>
                          </v-col>
                          <v-spacer></v-spacer>
                          <v-col cols="2" align-items="center">
                            <v-icon class="mr-1" size="16" color="grey">mdi-handshake-outline</v-icon>{{skills.meetings || 0}} <br>
                          </v-col>
                          <v-col cols="2" align-items="center"><v-icon class="mr-1" size="16" color="grey">mdi-arrow-up-down</v-icon>{{skills.rating || 0}}</v-col>
                          <v-col cols="auto"><v-btn @click="removeSkill(index)" icon><v-icon>mdi-cancel</v-icon></v-btn></v-col>
                        </v-row>
                      </div>
                    </div>


                  </v-tab-item>

                  <v-tab-item key="social" class="pt-6 pb-6">

                      <div class="mb-8">
                        <h5 class="app-heading">Any social proof to offer?</h5>
                        <v-row  class="mb-3 mx-0 pa-2 "  v-for="(link, index) in localUser.links" :key="`link-${index}`">

                        <v-col>
                          <ValidationProvider rules="required|url" v-slot="{ errors }">
                            <v-text-field
                              dense
                              :error-messages="errors[0]"
                              placeholder="https://website.com"
                              solo flat background-color="grey lighten-3" hide-details="auto"
                              v-model.trim="link.url"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="auto">
                          <v-btn @click="removeLink" icon><v-icon>mdi-cancel</v-icon></v-btn>
                        </v-col>
                      </v-row>
                      <div class="">
                        <v-btn small text color="primary" @click="addLink"><v-icon class="mr-2">mdi-comment-plus-outline</v-icon> Add Social Link</v-btn>
                      </div>
                    </div>

                  </v-tab-item>

                  <!-- <v-tab-item key="pro" class="pt-6 pb-6">
                    <div class="mb-8">
                      <h5 class="app-heading mb-4"><v-icon class="mr-1" color="primary">mdi-check-decagram</v-icon> Pro Accounts</h5>
                      <p class="primary--text">Coming Soon!</p>
                      <ul class="mb-4">
                        <li>Calendar Integration: Google + Outlook</li>
                        <li>Show meeting availability</li>
                        <li>Group rates by skill/interest</li>
                      </ul>
                      <v-btn color="black" dark>Notify Me!</v-btn>
                    </div>
                  </v-tab-item> -->
            </v-tabs-items>







              </v-col>
            </v-row>

      </v-col>

      <!-- <v-col cols="12">
        <v-btn color="success" @click="addCalendar()">Add Calendar</v-btn>
        <iframe style="width:100%; height:70vh" :src="calAuthUrl" v-if="calAuthUrl" class="d-block"></iframe>
      </v-col> -->
    </v-row>

</ValidationObserver>

    <!-- snackbar -->
    <snackbar :snackbar="snackbar" v-if="showSnackbar"></snackbar>


  </v-container>
</div>
</div>
</template>

<script>
const snackbar = () => import("@/components/shared/snackbar.vue");
const uploadImage = () => import("@/components/shared/upload-image.vue");
import { cloud, db } from '@/firebase';
import { mapGetters } from 'vuex'
import {IMaskComponent} from 'vue-imask';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
// import { isWebUri } from 'valid-url'

//RULE: required
extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    };
  },
  message:'This field is required.',
  computesRequired: true
});
//RULE: rate
extend('rate', {
  validate(value) {
    return
  },
  message:'This field is required.',
  computesRequired: true
});
//RULE: min:2
extend('min', {
  validate(value, args) {
    return value.length >= args.length;
  },
  message:'Must be at least 2 letters long',
  params: ['length']
});
//RULE: url
extend('url', {
  validate(value){
    const regexp = new RegExp('^(https?:\\/\\/)?'+ // protocol
   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
   '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
   '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
   '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            if (regexp.test(value))return true
            else return false
  },
  message:'Not a valid url?'
});


export default {
  name: "profile",
  components:{
    uploadImage, ValidationProvider, ValidationObserver,snackbar,
    'imask-input': IMaskComponent,
  },
  firestore: {
    skillsList: db.collection('skills').orderBy('name'),
  },
  computed:{
    userId: function (){ return this.$store.state.userId},
    user: function (){ return this.$store.state.user},
     getPercentages(arr){
        return true;
    },
    sortedSkills(){
      function compare(a, b) {
        return a.name > b.name ? 1: -1
      }
      return this.localUser.skills.slice().sort(compare);
    },
  },
  watch:{
    user: {
      handler: function(val){
        process.env.NODE_ENV === "development" ? console.log( 'watch user', this.$isUndefined(this.user), this.$isEmpty(this.user), this.user ) : null;
        if(!this.$isEmpty(val) && this.$isEmpty(this.localUser)){
          this.localUser = this.$clone(val)
        }
      },
      immediate: true
    },
    skills: function (value, prev){
      let vThis = this;
      if(!vThis.$isEmpty(value)){
        // process.env.NODE_ENV === "development" ? console.log( 'real value...', value[0]) : null;

        let val = null;
        if(vThis.$isUndefined(value[0].name)){val = value[0]}
        else{val = value[0].name}

        let existsAlready = vThis.localUser.skills.findIndex(function(post){
          // console.log(val, post.name,post.name == val);
          if (post.name == val){
            return true
          }
        });

        if(existsAlready == -1){
          let v = {
            name: val.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
            value: val.split(" ").join(""),
            rating: null,
            meetings: null,
            new:true,
            valid:true,
          }
          vThis.localUser.skills.push(v)
        }
        else{
          vThis.triggerSnackbar({color: 'error', text:'Skill Already Added'})
          // vThis.skillsSnackbar.show = true;
        }
        vThis.skills = [];
      }
    },
  },
  data(){
    return{
      userLoaded: false,
      localUser: [],
      currency:[{
        // mask: 'd0000',
        lazy: false,
        // blocks: {
        //   d: {
            mask: Number,
            thousandsSeparator: ',',
            scale: 2,
            signed: false,
            min:1,
            max:500
        //   },
        // },
      }],
      search:null,
      searchSkills: null,
      skillsSnackbar: {
        show:false,
        color: 'red',
        message: 'Skill Already Chosen'
      },
      skills:[],
      skillsList:[],
      socialNetworks:[
        {text: 'facebook', icon: 'mdi-facebook'},
        {text: 'Instagram', icon: 'mdi-twitter'},
        {text: 'Twitter', icon: 'mdi-instagram'},
        {text: 'Youtube', icon: 'mdi-youtube'},
        {text: 'LinkedIn', icon: 'mdi-linkedin'},
        {text: 'Website', icon: 'mdi-web'},
      ],
      calAuthUrl:false,
      isSaving:false,
      isLoaded: false,
      // localUser: {},
      dialog:false,
      snackbar: {},
      showSnackbar:false,
      tab: 'about',
      avatar: "https://avataaars.io/?accessoriesType=Prescription02&avatarStyle=Transparent&clotheColor=Gray02&clotheType=Hoodie&eyeType=Happy&eyebrowType=UpDownNatural&facialHairColor=BlondeGolden&facialHairType=MoustacheMagnum&graphicType=Resist&hairColor=BrownDark&hatColor=Gray01&mouthType=Grimace&skinColor=Pale&topType=ShortHairShortWaved",
    }
  },

  methods:{
    // async getCalAuth(){
    //     let getCalendarAuth = cloud.httpsCallable('getCalendarAuth');
    //     let token = await getCalendarAuth().then(function(result) {
    //       process.env.NODE_ENV === "development" ? console.log( 'results from getCalendarAuth', result ) : null;
    //       return result.data;
    //     }).catch(function(error) {
    //       process.env.NODE_ENV === "development" ? console.log( 'error', error ) : null;
    //     });
    //     return token
    // },
    // addCalendar(){
    //   let vThis = this;
    //   this.getCalAuth().then(url => {
    //     vThis.calAuthUrl = url;
    //   })
    // },
    remove (item) {
        const index = this.skillsList.indexOf(item.name)
        if (index >= 0) this.skillsList.splice(index, 1)
      },
    checkUserStatus(){
      let vThis = this;
      vThis.profileChecker = setInterval(() => {
        vThis.$store.dispatch('checkUserStatus').then((x)=>{
          process.env.NODE_ENV === "development" ? console.log( 'x',x ) : null;
          if(Object.hasOwnProperty.call(x, 'account')){
            clearInterval(vThis.profileChecker)
          }
        })
      },1000);

    },
    uploadedPhotos(data){
      process.env.NODE_ENV === "development" ? console.log( 'did photos update?', data ) : null;
      // this.localUser.photoURL = {
      //   original:data.original,
      //   cropped:data.cropped,
      //   thumb:data.thumb
      // }
      this.localUser.photoURL[data.location] = data.url;
      // this.localUser.photoURL['original'] = data.original;
      // this.localUser.photoURL['cropped'] = data.cropped;
      // this.localUser.photoURL['thumb'] = data.thumb;
    },
    closeModal(data){
      process.env.NODE_ENV === "development" ? console.log( 'modal closed', data ) : null;
      this.dialog = data;
    },
    itChanged(data){
      process.env.NODE_ENV === "development" ? console.log( 'changed', data ) : null;
      this.user[data.field] = data.text
    },
    updateUser(){
      let vThis = this;
      process.env.NODE_ENV === "development" ? console.log( 'observer', vThis.$refs.observer ) : null;
      process.env.NODE_ENV === "development" ? console.log( 'observer', vThis.$refs.observer.valid ) : null;
      vThis.$refs.observer.validate().then(results =>{
        //start loading for saving
        vThis.isSaving = true;
        vThis.addCloudSkills()
        .then((x)=> db.collection('users').doc(vThis.userId).update(vThis.localUser))
        .then(result=>{
          // process.env.NODE_ENV === "development" ? console.log( 'saved user' ) : null;
          vThis.$router.push({ name: 'User', params: { userName: vThis.user.userName } })
        })
      })


    },
    addLink(){
      this.localUser['links'].push({})
    },
    removeLink(item){
      process.env.NODE_ENV === "development" ? console.log( 'item', item ) : null;
      this.localUser.links.splice(this.localUser.links.indexOf(item), 1)
      this.localUser.links = [...this.localUser.links]
    },
    removeSkill(index){
      let skillsArr = this.sortedSkills
        skillsArr.splice(index, 1)
        this.localUser.skills = skillsArr
    },
    inSkillsList(item){
      let vThis = this;
      if(!this.$isUndefined(item)){
        // process.env.NODE_ENV === "development" ? console.log( 'item', item ) : null;
        let result = vThis.localUser.skills.findIndex(function(skill){
          // process.env.NODE_ENV === "development" ? console.log( 'skill', skill.name, skill.name == item ) : null;
          if (skill.name == item){
            return true;
          }
        });
        // process.env.NODE_ENV === "development" ? console.log( 'is it working?', result ) : null;
        return result == -1 ? false : true;
      }
    },
    async addCloudSkills(){
      let vThis = this;
      const promises = [];
      vThis.localUser.skills.forEach(async (skill, index) =>{
        if(!vThis.$isUndefined(skill.new)){
          process.env.NODE_ENV === "development" ? console.log( 'skill new.....', skill ) : null;
          delete skill.new
          let added = await vThis.putSkill(skill)
          promises.push(added)
        }
      })
      return promises;
    },
    async putSkill(skill){
      // return true
      let addNewSkill = cloud.httpsCallable('addNewSkill');
      let addedSkill = await addNewSkill({skill:skill}).then(function(result) {
        process.env.NODE_ENV === "development" ? console.log( 'results from addCloudSkill', result ) : null;
        return result.data;
      }).catch(function(error) {
        process.env.NODE_ENV === "development" ? console.log( 'error', error ) : null;
        return {error:error}
      });
      return addedSkill;
    },
    async isUsernameUnique() {
      let vThis = this;
      let isUniqueUserName = cloud.httpsCallable('isUniqueUserName');
      if(vThis.user.userName === vThis.localUser.userName){
        return true;
      }
      else{

        let checked = await isUniqueUserName({userName:vThis.localUser.userName}).then(function(result) {
          process.env.NODE_ENV === "development" ? console.log( 'result, local, user, if', result.data.message) : null;
          if(result.data.message === true){
            return true
          }
          else{
            return false
          }
        }).catch(function(error) {
          process.env.NODE_ENV === "development" ? console.log( 'error', error ) : null;
          return false
        });
        return checked;
      }
    },
    triggerSnackbar(data){
      this.showSnackbar = true;
      this.snackbar = data;
    }
  },
  created(){
    let vThis = this;
    // if(this.$vuetify.breakpoint.mdAndUp){
    //   this.$store.dispatch('toggleDrawer', true);
    // }
    if(Object.hasOwnProperty.call(this.$route.query, 'newAccount')){
      this.showNewAccount = true;
      process.env.NODE_ENV === "development" ? console.log( 'profile user', this.$isEmpty(this.$store.state.user) ) : null;
      process.env.NODE_ENV === "development" ? console.log( 'profile user', this.user ) : null;
      if(this.user == undefined || this.$isEmpty(this.user)){
        process.env.NODE_ENV === "development" ? console.log( 'no user found' ) : null;
        this.checkUserStatus();
      }
    }
    else{
      // process.env.NODE_ENV === "development" ? console.log( !this.$isEmpty(this.user) ) : null;

        // this.setUp(this.user)

    }
  },
  mounted(){
    extend("unique", {
        validate: this.isUsernameUnique,
        message: "Username already taken"
      });
      // this.setUp(this.user)

  }
};
</script>


<style lang="scss">
.profile.page{
  background-color: #f2f2f2;
  position: relative;



  // padding-left: 280px;
  form{
    width:100%;
    min-height: 100%;
    z-index: 99;
  }
  .v-slide-group__prev, .v-slide-group__next{
    display: none!important;
  }
  .edit-photo{
    margin-top: -40px;
  }
  .profile-photo{
    .profile-img{
      max-width: 100%;
      border-radius: 6px;
      border: 3px solid white;
    }
    h2{
      line-height: 95%;
    }
    .company-links{
      text-decoration: none;
    }
  }
  .rate-field{
    min-width: 40px;
    max-width: 60px!important;
    .v-text-field__details{
      display: none;
    }
    .v-input__slot{
      padding: 0 5px!important;
      margin:0!important;
      input{
        // text-align:right;
      }
    }
  }
  .social-icon{
    // max-width: 100px;
    // width:100%;
  }
  .at-sign{
    width:40px;
    fill: var(--v-primary-base);
  }
  .rate{
    font-size: 24px;
    color:black;
    font-weight: bold;
    width:60px;
    background-color: white;
    padding: 2px;
    border-radius: 6px;
  }
  // .skills{
  //   .v-chip button{
  //     opacity: .5;
  //   }
  // }
  .skills{
    .v-input__control .v-input__append-inner{
      display: none;
    }
    .skill{
      border-bottom: 1px solid #f2f2f2;
    }
  }
  .v-card{
    .v-card__text{
      position: relative;
      .editBtn{
        position: absolute;
        right: 30%
      }
    }
  }
  &.mobile{
    // padding-top:0;
    padding-left: 0;
    .container{
      padding: 0;
      .row {
        margin: 0;
        > div{
          // padding: 0;
        }
      }
      .v-card{
        padding-bottom: 12px;
      }
    }
    .profile-photo{
      max-width: 50vw;
      margin: 0 auto;
    }
  }
}
</style>
